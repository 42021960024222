<template>
  <div class="note-found">
    Page not found
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style scoped lang="scss">

</style>
